<!--
=========================================================
* Vue Material Dashboard 2 - v2.0.0
=========================================================

* Product Page: https://creative-tim.com/product/vue-material-dashboard-2
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
-->
<template>
<div @mousemove="resetTimer">
  <div class="spinner-container">
  <div class="lds-facebook"><div></div><div></div><div></div></div>
  </div>
  <sidenav
    :custom_class="color"
    :class="[isRTL ? 'fixed-end' : 'fixed-start']"
    v-if="showSidenav"
  />
  <main
    class="main-content position-relative max-height-vh-100 h-100 overflow-x-hidden"
  >
    <!-- nav -->
    <navbar
      :class="[isNavFixed ? navbarFixed : '', isAbsolute ? absolute : '']"
      :color="isAbsolute ? 'text-white opacity-8' : ''"
      :minNav="navbarMinimize"
      v-if="showNavbar"
    />
    <router-view />
  </main>
  </div>
</template>
<script>
import Sidenav from './examples/Sidenav'
import swal from 'sweetalert'
import Navbar from '@/examples/Navbars/Navbar.vue'
import { mapMutations, mapState } from 'vuex'

export default {
  name: 'App',
  components: {
    Sidenav,
    Navbar
  },
  data () {
    return {
      logoutTimer: null,
      isRTL: true
    }
  },
  watch: {
    $route: {
      handler (to, from) {
        document.title = 'Th-Event ' + '| ' + to.meta.title || 'Idea Learning System'
      },
      immediate: true
    },
    isRTL: function () {
      this.isRTL = localStorage.Lang === 'ar' || !(localStorage.Lang)
      this.$store.state.isRTL = localStorage.Lang === 'ar' || !(localStorage.Lang)
    },
    dir: function () {
      this.dir = localStorage.dir
    }

  },
  created () {
  },
  methods: {
    ...mapMutations(['toggleConfigurator', 'navbarMinimize']),
    logout () {
      // const current = global
      if (localStorage.token) {
        const formData = new FormData()
        formData.append('token', localStorage.token)
        this.$http
          .post(this.$hostUrl + 'logout', formData, {
            headers: {
              'Content-Type': 'multipart/form-data',
              'Access-Control-Allow-Origin': '*'
            }
          })
          .then((response) => {
            if (!response.data.error) {
              localStorage.removeItem('token')
              this.$router.push({ name: 'SignIn' })
            } else {
              swal({ title: response.data.message, buttons: 'تم' })
              localStorage.removeItem('token')
              this.$router.push({ name: 'SignIn' })
            }
          })
      } else {
        localStorage.removeItem('token')
        global.$router.push({ name: 'SignIn' })
      }
    },
    resetTimer () {
      clearTimeout(this.logoutTimer)
      this.logoutTimer = setTimeout(() => {
        swal('رجاء تسجيل الدخول مرة أخري ').then((oKBtn) => {
          this.logout()
        })
      }, 90 * 60 * 1000)
    }
  },
  mounted () {
    console.log(this.isRTL)
    this.isRTL = localStorage.Lang === 'ar' || !(localStorage.Lang)
    if (this.$store.state.isRTL === true) {
      document.querySelector('html').setAttribute('lang', 'ar')
      document.querySelector('html').setAttribute('dir', 'rtl')
      document.querySelector('#app').classList.add('rtl')
      document.querySelector('#app').classList.remove('ltr')
    } else {
      document.querySelector('body').style.fontSize = '16px !important'
      document.querySelector('html').removeAttribute('lang')
      document.querySelector('html').removeAttribute('dir')
      document.querySelector('#app').classList.remove('rtl')
      document.querySelector('#app').classList.add('ltr')
    }
    this.resetTimer()
  },
  computed: {
    ...mapState([
      'isRTL',
      'color',
      'isAbsolute',
      'isNavFixed',
      'navbarFixed',
      'absolute',
      'showSidenav',
      'showNavbar',
      'showFooter',
      'showConfig',
      'hideConfigButton'
    ])
  },
  beforeUnmount () {
    this.$store.state.isRTL = true
    document.querySelector('html').setAttribute('lang', 'ar')
    document.querySelector('html').setAttribute('dir', 'rtl')
    document.querySelector('#app').classList.add('rtl')
  },
  beforeMount () {
    // this.$store.state.isRTL = true
    // document.querySelector('html').setAttribute('lang', 'ar')
    // document.querySelector('html').setAttribute('dir', 'rtl')
    // document.querySelector('#app').classList.add('rtl')

    this.$store.state.isTransparent = 'bg-transparent'

    const sidenav = document.getElementsByClassName('g-sidenav-show')[0]

    if (window.innerWidth > 1200) {
      sidenav.classList.add('g-sidenav-pinned')
    }
  }
}
</script>
<style lang="scss">
.ltr
 {
  @import url('https://fonts.googleapis.com/css2?family=Open+Sans&display=swap');
  font-family: 'Open Sans', sans-serif !important;
   font-size: 17px;

  label, .input-group, button, th, h6, .h6, p, span, a, div {
    font-family: 'El Messiri', sans-serif !important;
     font-size: 16px;
  }
  button,.badge{
    font-size: 13px !important;
  }
.navbar-brand span {
    line-height: 24px;
    font-size: 37px;
    font-weight: bold
}
  .table-responsive {
    direction: ltr !important;
  }

  .head-ul {
    gap: 59px !important;
  }

  .new-student {
    float: right !important;
    margin-right: 30px !important;
  }

  label {
    float: left !important;
  }
  .rating-con{
    display: flex;
    flex-direction: row-reverse;
    gap: 8px;
  }
  .vue-tel-input,.main-tel{
    width:100%
  }
  .cntry-cd{
    direction: ltr;
  }
}
</style>

<style>
.swal-button-container{
  display:flex !important;
  justify-content: center !important;
  align-items: center !important;
}
.spinner-container{
     width: 100%;
    height: 100%;
    background: #67ad5680;
    z-index: 999999;
    position: absolute;
    display:none;
}
.lds-facebook {
  display: inline-block;
  position: absolute;
  z-index:99999;
  background:#222;
    top: 50%;
    right: 57%;
    transform: translate(-50%,-50%);
}
.lds-facebook div {
  display: inline-block;
  position: absolute;
  left: 8px;
  width: 16px;
  background: #222;
  animation: lds-facebook 1.2s cubic-bezier(0, 0.5, 0.5, 1) infinite;
}
.lds-facebook div:nth-child(1) {
  left: 8px;
  animation-delay: -0.24s;
}
.lds-facebook div:nth-child(2) {
  left: 32px;
  animation-delay: -0.12s;
}
.lds-facebook div:nth-child(3) {
  left: 56px;
  animation-delay: 0;
}
@keyframes lds-facebook {
  0% {
    top: 8px;
    height: 64px;
  }
  50%, 100% {
    top: 24px;
    height: 32px;
  }
}

</style>
