<template>
  <div class="container-fluid py-4 all-students">
    <div class="row">
      <div class="col-12">
        <div class="card my-4">
          <div class="card-header p-0 position-relative mt-n4 mx-3 z-index-2">
            <div
              class="bg-gradient-success shadow-success border-radius-lg pt-4 pb-3"
            >
            <div class="row">
              <div class="col-lg-6">
              <h6 class="text-white text-capitalize ps-3">  {{$t('message.usersList')}}</h6>
              </div>
               <div class="col-lg-6">
                 <router-link to="/users/create">

               <span class="badge badge-sm new-student"><i  class="fas fa-user-plus"></i>{{$t('message.createUser')}}  </span>
                 </router-link>
               </div>
              </div>
            </div>
          </div>
          <div class="card-body px-0 pb-2">

          <div class="row">
            <vdtnet-table class=" table-striped table table-borderless "
            :jquery="jq"
            ref="table"
            :fields="fields"
            :opts="options"
            @edit="doAlertEdit"
            @show="doAlertShow"
            @delete="doAlertDelete"
            @reloaded="doAfterReload"
            @table-creating="doCreating"
            @table-created="doCreated"
          >
          </vdtnet-table>
          </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VdtnetTable from 'vue-datatables-net'
import $ from 'jquery'

export default {
  name: 'Users',
  components: {
    VdtnetTable
  },
  data () {
    return {
      pagination: {
        rowsPerPage: 30
      },
      jq: $,
      options: {
        ajax: {
          beforeSend: function (xhr) {
            xhr.setRequestHeader(
              'Authorization',
              'Bearer ' + localStorage.token
            )
          },
          url: `${this.$hostUrl}users`,
          dataSrc: (json) => {
            return json.data
          }
        },
        buttons: [
          {
            extend: 'print',
            text: '',
            titleAttr: 'طباعة',
            className: 'fas fa-print btn-primary',
            exportOptions: {
              columns: 'th:not(:last-child)'
            },
            customize: function (win) {
              $(win.document.body).css('direction', 'rtl')
            }
          },
          {
            extend: 'copy',
            text: '',
            className: 'fas fa-copy btn-warning',
            titleAttr: 'نسخ',
            exportOptions: {
              columns: 'th:not(:last-child)'
            }
          },
          {
            extend: 'excel',
            text: '',
            titleAttr: 'إكسل',
            className: 'fas fa-table btn-success',
            exportOptions: {
              columns: 'th:not(:last-child)'
            }
          }
        ],
        dom:
          "<'row'<'col-sm-12 col-md-6'f><'col-sm-12 col-md-6 added'l>>" +
          "<'row'<'col-sm-12'Btr>>" +
          "<'row'<'col-sm-12 col-md-6'p><'col-sm-12 col-md-6'i>>",
        paging: true,
        language: {
          url: this.$t('message.url'),
          processing: '<i class="fa fa-spinner fa-spin" style="font-size:40px;color:#7aeb7f"></i>'
        },
        responsive: false,
        processing: true,
        searching: true,
        searchDelay: 1500,
        destroy: true,
        ordering: true,
        lengthChange: true,
        order: [[3, 'desc']],
        serverSide: true,
        fixedHeader: true,
        saveState: true,
        select: false
      },
      fields: {
        id: {
          label: 'رقم',
          sortable: true,
          searchable: true
        },
        name: {
          label: 'الاسم',
          sortable: true,
          searchable: true
        },
        created_at: {
          label: this.$t('message.createdAt'),
          sortable: true,
          searchable: true
        },
        actions: {
          isLocal: true,
          label: this.$t('message.operations'),
          defaultContent:
            '&ensp;<a href="javascript:void(0);" data-action="edit"><i class="fas fa-edit" title="تعديل"></i> </a>' +
            '&ensp;<a href="javascript:void(0);" data-action="show"><i class="fas fa-eye" title="عرض"></i> </a>'
        }
      }
    }
  },
  methods: {
    getUserRole (data) {
      return data[0].name
    },
    doAlertShow (data) {
      this.$router.push({
        path: `/users/${data.id}/show`,
        params: { id: data.id }
      })
    },
    doAlertEdit (data) {
      this.$router.push({
        path: `/users/${data.id}/edit`,
        params: { id: data.id }
      })
    }
  }
}
</script>
<style>
.ps-3 {
    padding-right: 1rem !important;}
    .me-3 {
    margin-left: 1rem !important;}
</style>
