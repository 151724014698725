<template>
     <div class="container-fluid py-4 add-student">
    <div class="row">
      <div class="col-12">
        <div class="card my-4">
          <div class="card-header p-0 position-relative mt-n4 mx-3 z-index-2">
            <div
              class="bg-gradient-success shadow-success border-radius-lg pt-4 pb-3"
            >
              <h6 class="text-white text-capitalize ps-3">    {{$t('message.updateUser')}}</h6>
            </div>
          </div>
          <div class="card-body px-0 pb-2">
                   <form @submit="EditUser" role="form" class="text-start mt-3">

                             <div class='row'>
                     <div class="mb-3 col-lg-6">
                    <label>  {{$t('message.name')}} <span class="red">*</span> </label>
                  <vmd-input
                    id=""
                    type="text"
                   v-model=" user.name"
                   placeholder="اسم المستخدم"
                  />
                </div>
                  <div class="mb-3 col-lg-6">
                    <label>   {{$t('message.email')}} <span class="red">*</span></label>
                  <vmd-input
                    id=""
                    type="email"
                   v-model="  user.email  "
                    placeholder=" البريد الإلكتروني"
                  />
                </div>
                </div>
                <div class="row">
                  <div class="col-lg-12">
                  <p class="password-text text-warning">{{$t('message.editpasswordMessage')}}</p>
                  </div>
                <div class="mb-3 col-lg-6">
                        <label> {{$t('message.password')}} </label>
                  <vmd-input
                    id="password"
                    type="password"
                    v-model=" user.password "
                     :placeholder=" $t('message.password') "
                  />
                </div>
                 <div class="mb-3 col-lg-6">
                      <label>{{$t('message.confirmPassword')}}</label>
                  <vmd-input
                    id="password"
                    type="password"
                    v-model=" user.passwordConfirmation "
                     :placeholder=" $t('message.confirmPassword')  "
                  />
                </div>
                       </div>
                       <div class="row">
                <div class="text-center col-lg-6">
                  <vmd-button
                    class="my-4 mb-2"
                    variant="gradient"
                    color="info"
                    >  {{$t('message.save')}}</vmd-button
                  >
                </div>
                 <div class="text-center col-lg-6">
                  <router-link to="/users">
                  <vmd-button
                  type='reset'
                    class="my-4 mb-2"
                    variant="gradient"
                    color="warning"
                    >  {{$t('message.back')}}</vmd-button
                  >
                  </router-link>
                </div>
                       </div>
              </form>
          </div>
        </div>
      </div>
    </div>
     </div>
</template>
<script>
import VmdInput from '@/components/VmdInput.vue'
import VmdButton from '@/components/VmdButton.vue'
export default {
  name: 'EditUser',
  components: {
    VmdInput,
    VmdButton
  },
  data () {
    return {
      user: {},
      roles: [],
      selectedRole: '',
      userTypes: [],
      selectedType: '',
      branches: [],
      selectedBranch: '',
      centerType: '',
      instructors: [],
      selectedInstructor: '',
      errors: []
    }
  },
  created () {
    this.centerType = localStorage.centerType
    this.$http.get(`${this.$hostUrl}users/${this.$route.params.id}`).then((response) => {
      this.user = response.data.data
    })
  },
  methods: {
    async EditUser (e) {
      e.preventDefault()
      const formData = new FormData()
      formData.append('name', this.user.name)
      formData.append('email', this.user.email)
      if (this.user.password) {
        formData.append('password', this.user.password)
      }
      if (this.user.passwordConfirmation) {
        formData.append('password_confirmation', this.user.passwordConfirmation)
      }
      formData.append('_method', 'PUT')

      this.errors = await this.postRequest(formData, this.$hostUrl + 'users/' + this.$route.params.id, '/users')
    }
  }
}
</script>
<style src="vue-multiselect/dist/vue-multiselect.css"></style>
